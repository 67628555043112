import React, { useMemo, useState } from "react";
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	flexRender,
} from "@tanstack/react-table";

export function Thead({ children }) {
	return <thead className="reactable-header">{children}</thead>;
}

export function Th({ column, children }) {
	return (
		<th className="reactable-header-sortable" data-column={column}>
			{children}
		</th>
	);
}

export function Tr({ children }) {
	return <tr className="reactable-row">{children}</tr>;
}

export function Td({ column, value, children }) {
	return (
		<td className="reactable-cell" data-column={column} data-value={value}>
			{children}
		</td>
	);
}

export function Table({
	className,
	children,
	itemsPerPage = 500,
	sortable = [],
	filterable = [],
	filterPlaceholder = "",
	filterBy = "",
	onFilter,
	onSort,
	defaultSort,
	defaultSortDescending = false,
	previousPageLabel = "<",
	nextPageLabel = ">",
}) {
	const tableClass = className ? `reactable ${className}` : "reactable";

	const childrenArray = React.Children.toArray(children);
	const thead = childrenArray.find((c) => c.type === Thead);
	const rows = childrenArray.filter((c) => c.type === Tr);

	if (!thead || rows.length === 0) {
		return null;
	}

	const [sorting, setSorting] = useState(() => {
		if (defaultSort && typeof defaultSort === "object") {
			return [
				{
					id: defaultSort.column,
					desc: defaultSort.direction === "desc",
				},
			];
		}
		return [];
	});
	const [pageIndex, setPageIndex] = useState(0);
	const [globalFilter, setGlobalFilter] = useState(filterBy || "");

	const getSortingFunction = (colId) => {
		const foundSort = sortable.find((s) => {
			if (typeof s === "string") return s === colId;
			if (typeof s === "object" && s.column) return s.column === colId;
			return false;
		});

		let sortFunction =
			typeof foundSort === "object" && foundSort.sortFunction
				? foundSort.sortFunction
				: (a, b) => {
						if (a === b) return 0;

						const isDate =
							colId.toLowerCase().includes("date") ||
							colId.toLowerCase().includes("created") ||
							colId.toLowerCase().includes("published") ||
							colId.toLowerCase().includes("updated") ||
							colId.toLowerCase().includes("startDate") ||
							colId.toLowerCase().includes("endDate") ||
							colId.toLowerCase().includes("createdAt");

						const aStr = String(a);
						const bStr = String(b);

						if (isDate) {
							return new Date(aStr) - new Date(bStr);
						}

						return aStr.localeCompare(bStr, "sv");
				  };

		return (rowA, rowB) => {
			const a = rowA.getValue(colId);
			const b = rowB.getValue(colId);

			const extractSortableValue = (val) => {
				if (val === null || val === undefined) return "";
				if (typeof val === "symbol") return "";

				if (typeof val === "object") {
					if ("value" in val) return val.value;
					if (val.props) return val.props.value ?? val.props.children ?? "";
					return Object.values(val).join(" ");
				}

				return String(val);
			};

			const aVal = extractSortableValue(a);
			const bVal = extractSortableValue(b);

			return sortFunction(aVal, bVal);
		};
	};

	const columns = useMemo(() => {
		return React.Children.toArray(thead.props.children)
			.filter((th) => th && React.isValidElement(th) && th.props?.column)
			.map((th) => {
				const sortFn = getSortingFunction(th.props.column);
				return {
					accessorKey: th.props.column,
					header: th.props.children,
					enableSorting: Boolean(sortFn),
					sortingFn: sortFn,
				};
			});
	}, [thead.props.children, sortable]);

	const data = useMemo(() => {
		return rows.map((tr) => {
			const rowData = {};
			React.Children.forEach(tr.props.children, (td) => {
				if (!td || !td.props?.column) return;
				const colName = td.props.column;
				rowData[colName] = {
					value: td.props.value ?? td.props.children ?? "",
					component: td.props.children,
				};
			});
			return rowData;
		});
	}, [rows]);

	const enablePagination = data.length > itemsPerPage;

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			globalFilter,
			pagination: {
				pageIndex,
				pageSize: itemsPerPage || 500,
			},
		},
		onSortingChange: setSorting,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		...(enablePagination && {
			getPaginationRowModel: getPaginationRowModel(),
		}),
	});

	const handleSortClick = (header) => {
		if (header.column.getCanSort()) {
			header.column.toggleSorting();
			if (onSort) {
				onSort({
					column: header.column.id,
					direction: header.column.getIsSorted(),
				});
			}
		}
	};

	const goPrev = () => setPageIndex((old) => Math.max(old - 1, 0));
	const goNext = () =>
		setPageIndex((old) => Math.min(old + 1, table.getPageCount() - 1));

	return (
		<table className={tableClass}>
			<thead className="reactable-header">
				<tr>
					<th
						colSpan={table.getAllColumns().length}
						style={{ textAlign: "left" }}
					>
						<input
							placeholder={filterPlaceholder || "Search..."}
							value={globalFilter || ""}
							onChange={(e) => {
								const val = e.target.value;
								table.setGlobalFilter(val);
								if (onFilter) {
									onFilter(val);
								}
							}}
							disabled
						/>
					</th>
				</tr>

				{table.getHeaderGroups().map((hg) => (
					<tr key={hg.id}>
						{hg.headers.map((header) => {
							const sorted = header.column.getIsSorted();
							return (
								<th
									key={header.id}
									className={
										"reactable-header-sortable " +
										(sorted === "asc"
											? "reactable-header-sort-asc"
											: sorted === "desc"
											? "reactable-header-sort-desc"
											: "")
									}
									onClick={() => handleSortClick(header)}
								>
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
								</th>
							);
						})}
					</tr>
				))}
			</thead>

			<tbody className="reactable-data">
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id} className="reactable-row">
						{row.getVisibleCells().map((cell) => {
							const val = cell.getValue();
							const content =
								val && typeof val === "object" && "component" in val
									? val.component
									: val;

							return (
								<td key={cell.id} className="reactable-cell">
									{content instanceof Date
										? content.toLocaleDateString()
										: content || ""}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>

			{enablePagination && (
				<tfoot className="reactable-pagination">
					<tr>
						<td colSpan={table.getAllColumns().length}>
							<div className="pagination-controls">
								<button onClick={goPrev} disabled={!table.getCanPreviousPage()}>
									{previousPageLabel}
								</button>

								<span>
									Sida {table.getState().pagination.pageIndex + 1} av{" "}
									{table.getPageCount()}
								</span>

								<button onClick={goNext} disabled={!table.getCanNextPage()}>
									{nextPageLabel}
								</button>
							</div>
						</td>
					</tr>
				</tfoot>
			)}
		</table>
	);
}
